import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { validate } from 'isemail';

import Input from '../../elements/Input';
import { firestore } from '../../../firebase';

import Loading from '../../../assets/lottie/loading.json';
import Success from '../../../assets/lottie/success.json';

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {
    children: null
};

const Email = ({
    className,
    children
}) => {
    const ref = useRef();
    const successRef = useRef();
    const loadingRef = useRef();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        successRef.current = lottie.loadAnimation({
            loop: false,
            name: "success",
            animType: 'svg',
            wrapper: ref.current,
            animationData: Success
        });

        loadingRef.current = lottie.loadAnimation({
            loop: true,
            name: "loading",
            animType: 'svg',
            wrapper: ref.current,
            animationData: Loading
        });
    }, []);

    useEffect(() => {
        if (success) {
            successRef.current.play();
            successRef.current.show();

            setTimeout(() => {
                setEmail('');
                setSuccess(false);
            }, 2500);
        }

        if (!success) {
            successRef.current.stop();
            successRef.current.hide();
        }
    }, [success]);

    useEffect(() => {
        if (loading) {
            loadingRef.current.play();
            loadingRef.current.show();
        }

        if (!loading) {
            loadingRef.current.stop();
            loadingRef.current.hide();
        }
    }, [loading]);

    async function handleSubmit() {
        if (loading) {
            return;
        }

        if (success) {
            return;
        }

        if (error) {
            setError(null);
        }

        if (!email) {
            return setError('Email field cannot be empty');
        }

        if (typeof email !== "string") {
            return setError('The email you\'ve entered is invalid, please enter a valid email address');
        }

        const trimmedEmail = email.trim().toLowerCase();

        if (!validate(trimmedEmail)) {
            return setError('The email you\'ve entered is invalid, please enter a valid email address');
        }

        setLoading(true);

        try {
            await firestore.collection('Prospects')
                .doc(trimmedEmail)
                .set({ email: trimmedEmail, createdOn: new Date() });

            setSuccess(true)
            setLoading(false);
        }
        catch (err) {
            console.error(err);
            setLoading(false);
            setError('There was an unknown issue, please try again or email admin@cracca.com if this error persists');
        }
    }

    const classes = classNames(
        'cta-action',
        loading && 'loading',
        className
    );

    return (
        <div className={classes}>
            <Input
                value={email}
                type="email"
                label="Subscribe"
                labelHidden
                readOnly={loading || success}
                hasIcon="right"
                placeholder="Email address"
                onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                onChange={(e) => setEmail(e.target.value)}
            >
                <div ref={ref} onClick={handleSubmit}>
                    {
                        !loading && !success && <span>Sign up</span>
                    }
                </div>
            </Input>
        </div >
    );
}

Email.propTypes = propTypes;
Email.defaultProps = defaultProps;

export default Email;