import firebase from 'firebase';
import { REACT_APP_FIREBASE_CONFIG } from './utils/env';

firebase
    .initializeApp(REACT_APP_FIREBASE_CONFIG);

if (process.env.NODE_ENV !== 'production') {
    firebase
        .firestore()
        .useEmulator('localhost', 8080);
}

export const firestore = firebase.firestore();